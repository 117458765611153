.item-item {
  position: relative;
}
.item-details {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.item-text {
  color: #ffffff;
  width: 190px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.item-text1 {
  color: rgb(153, 153, 153);
  font-size: 24px;
  line-height: 36px;
}
.item-icon {
  fill: #ffffff;
  width: 30px;
  height: 30px;
}
@media(max-width: 991px) {
  .item-item {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .item-details {
    gap: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
  }
  .item-text {
    font-size: 16px;
    line-height: 24px;
  }
  .item-text1 {
    font-size: 14px;
    line-height: 21px;
  }
  .item-icon {
    width: 12px;
    height: 12px;
  }
}
