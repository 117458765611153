.tag-container {
  display: flex;
  position: relative;
}
.tag-tag {
  transition: 0.3s;
  font-family: "Verdana";
}
.tag-tag:hover {
  color: #fff;
  background-color: #235536;
}



@media(max-width: 991px) {
  .tag-tag {
    font-size: 12px;
    padding-top: 12px;
    white-space: nowrap;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 12px;
  }
}
@media(max-width: 479px) {
  .tag-root-class-name {
    width: auto;
    align-self: center;
  }
  .tag-root-class-name1 {
    width: auto;
  }
  .tag-root-class-name2 {
    width: auto;
  }
}
