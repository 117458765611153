.work-work {
  gap: 140px;
  width: 100%;
  display: flex;
  position: relative;
  column-gap: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-color: #151515;
  margin-bottom: 23px;
  border-bottom-width: 1px;
}
.work-image {
  height: 750px;
  align-self: center;
  object-fit: fill;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.work-details {
  flex: 1;
  width: auto;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  grid-row-gap: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.work-header {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.work-heading {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.work-text {
  color: rgb(21, 21, 21);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  text-transform: uppercase;
}
.work-link {
  display: contents;
}
.work-component {
  text-decoration: none;
}
.work-text1 {
  color: rgb(21, 21, 21);
  font-size: 24px;
  text-align: center;
  font-family: "Verdana";
  line-height: 36px;
}
.work-tags {
  gap: var(--dl-space-space-halfunit);
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.work-root-class-name {
  width: auto;
  height: auto;
  align-self: center;
}
.work-root-class-name1 {
  width: auto;
  height: auto;
  align-self: stretch;
}
.work-root-class-name2 {
  height: auto;
}
@media(max-width: 1600px) {
  .work-work {
    height: auto;
  }
  .work-details {
    align-items: center;
    justify-content: center;
  }
  .work-heading {
    gap: 12px;
    align-items: center;
    flex-direction: column;
  }
  .work-text {
    text-align: center;
  }
  .work-tags {
    gap: var(--dl-space-space-halfunit);
    margin: auto;
    flex-wrap: true;
    align-self: flex-start;
    align-items: center;
    padding-left: 0px;
    justify-content: center;
  }
  .work-root-class-name {
    height: auto;
    align-self: center;
  }
  .work-root-class-name1 {
    align-self: center;
  }
  .work-root-class-name2 {
    width: auto;
    height: auto;
    align-self: center;
  }
}
@media(max-width: 1200px) {
  .work-work {
    width: 90%;
    column-gap: var(--dl-space-space-unit);
  }
  .work-image {
    width: fit-content;
    padding: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .work-details {
    gap: var(--dl-space-space-unit);
  }
  .work-header {
    padding-left: 13px;
    padding-right: 13px;
  }
  .work-heading {
    gap: 12px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .work-text {
    text-align: center;
  }
  .work-root-class-name {
    width: auto;
    height: auto;
    max-width: auto;
    align-self: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .work-root-class-name1 {
    width: auto;
    height: auto;
    max-width: auto;
    align-self: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .work-root-class-name2 {
    width: auto;
    max-width: auto;
    align-self: center;
  }
}
@media(max-width: 991px) {
  .work-work {
    gap: 0px;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: 0;
    margin-bottom: 0;
    padding-right: 0;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .work-image {
    width: fit-content;
  }
  .work-details {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: 0px;
    justify-content: flex-start;
  }
  .work-header {
    gap: var(--dl-space-space-unit);
  }
  .work-heading {
    align-items: center;
  }
  .work-text {
    font-size: 24px;
    line-height: 21px;
  }
  .work-text1 {
    font-size: 18px;
    line-height: 24px;
  }
  .work-tags {
    margin: auto;
    padding: 0;
    flex-wrap: nowrap;
  }
  .work-root-class-name {
    height: auto;
  }
  .work-root-class-name1 {
    height: auto;
  }
  .work-root-class-name2 {
    height: auto;
  }
}
@media(max-width: 737px) {
  .work-work {
    align-items: center;
    margin-bottom: 0;
  }
  .work-tags {
    margin: auto;
    padding: 0;
    flex-wrap: nowrap;
  }
}
@media(max-width: 479px) {
  .work-work {
    align-items: center;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: 0;
    padding-right: var(--dl-space-space-halfunit);
  }
  .work-image {
    height: auto;
    max-width: 100%;
  }
  .work-details {
    flex: 1;
    width: var(--dl-size-size-xxlarge);
    height: 100%;
    align-self: center;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
  }
  .work-header {
    width: auto;
    padding: 0;
  }
  .work-heading {
    justify-content: center;
  }
  .work-text {
    text-align: center;
  }
  .work-text1 {
    align-self: flex-start;
  }
  .work-tags {
    width: auto;
    padding: 0;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
  }
  .work-root-class-name {
    max-width: 90%;
  }
  .work-root-class-name1 {
    height: auto;
    max-width: 90%;
  }
  .work-root-class-name2 {
    max-width: 90%;
  }
}
