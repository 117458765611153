.scroll-to-top-button {
  display: block;
  position: fixed;
  bottom: 3rem;
  /* right: 2rem; */
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  padding: 0.2rem 0.8rem;
  font-size: 2rem;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(225, 11, 227) 16.00%,rgb(77, 238, 234) 77.00%);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.scroll-to-top-button:hover {
  opacity: 0.7;
}

.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
  background-color: #151515;
}
.home-navbar-interactive {
  width: 100%;
  /* height: 6rem; */
  height:5rem;
  /* height: 125px  ; */
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.home-branding {
  gap: var(--dl-space-space-threeunits);
  width: auto;
  cursor: pointer;
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image {
  width: auto;
  /* height: 5rem; */
  padding: 5px 0;
  align-self: stretch;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-items {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
}
.home-links {
  gap: var(--dl-space-space-threeunits);
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-link01 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-link02 {
  text-decoration: none;
}
.home-link03 {
  text-decoration: none;
}
.home-link04 {
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  /* line-height: 0.4; */
  /* text-align: center; */
  /* align-items: center; */
  border-radius: 40px;
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(225, 11, 227) 16.00%,rgb(77, 238, 234) 77.00%);
}
.home-text {
  color: rgb(255, 255, 255);
  font-size: 1.1rem; 
  font-weight:600;
  /* padding: 0.5rem 0.3rem;  */
  font-style: normal;
  /* font-weight: 500; */
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-mobile-menu.open {
  display: block; /* Show the mobile menu when the open class is present */
  /* Add additional styles for the open state */
  transform: translateX(0%);
}
.home-button {
  font-size: 20px;
  background: linear-gradient(90deg, rgb(225, 11, 227) 16.00%,rgb(77, 238, 234) 77.00%);
  font-style: normal;
  font-weight: 500;
  padding-top: 20px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 20px;
  background-color: #47ece4;
}
.home-image02 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.home-text01 {
  color: #ffffff;
  display: flex;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 70rem;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #151515;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-branding1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.home-company {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.home-menu-close {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.home-items1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-links1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text06 {
  color: #ffffff;
}
.home-hero {
  align-items: flex-start;
  justify-content: center;
}
.home-header {
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  z-index: 1;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: 1%;
  animation-name: none;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  animation-delay: 0s;
  justify-content: center;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-title {
  color: rgb(255, 255, 255);
  width: 100%;
  /* font-size: 7rem; */
  font-size: 5.5rem;
  max-width: 35rem;
  align-self: flex-start;
  font-weight: normal;
  /* line-height: 115px; */
  line-height:5rem;
  text-transform: uppercase;
}
.home-description {
  color: rgb(255, 255, 255);
  width: 100%;
  /* font-size: 2.1rem; */
  font-size: 1.5rem;
  max-width: 50rem;
  font-family: "Verdana";
  /* line-height: 36px; */
  line-height:2rem;
}
.home-video1 {
  flex: 1;
  height: 100vh;
  display: none;
  z-index: -1;
}
.home-description1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 60rem;
  display: flex;
  align-self: center;
  column-gap: 150px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-image03 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.home-content {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  z-index: 1;
  position: relative;
  max-width: 70rem;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.home-text07 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-paragraph {
  color: var(--dl-color-gray-white);
  font-size: 2.5rem;
  font-family: "Verdana";
  line-height: 48px;
}
.home-link05 {
  font-size: 1.8rem;
  align-self: center;
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(225, 11, 227) 16.00%,rgb(77, 238, 234) 77.00%);
}
.home-text08 {
  color: rgb(255, 255, 255);
}
.home-featured {
  width: 100%;
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-header1 {
  width: auto;
  display: flex;
  padding: 1rem 1rem;
  margin-top: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-link06 {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-title1 {
  color: var(--dl-color-gray-black);
  margin: auto;
  font-size: 4.9rem;
  align-self: center;
  text-align: center;
}
.home-list {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-client {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: var(--dl-color-gray-white);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-gray-black);
  border-bottom-width: 1px;
}
.home-text09 {
  color: var(--dl-color-gray-white);
  width: 80%;
  font-size: 2.5rem;
  max-width: 100%;
  font-family: "Verdana";
  line-height: 3.2rem;
}
.home-link07 {
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(225, 11, 227) 16.00%,rgb(77, 238, 234) 77.00%);
}
.home-text10 {
  color: rgb(255, 255, 255);
}
.home-image04 {
  width: 90%;
  height: auto;
  object-fit: contain;
}
.home-services {
  gap: var(--dl-space-space-fiveunits);
  height: auto;
  display: flex;
  max-width: 100%;
  align-self: center;
  align-items: center;
  padding-top: 40px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-header2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-title2 {
  font-size: 7rem;
  align-self: center;
}
.home-grid {
  color: white;
  display: grid;
  grid-gap: var(--dl-space-space-fourunits);
  padding-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-gray-white);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr ;
}
.home-image05 {
  width: 100%;
  height: auto;
  background: black;
}
.home-about {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.home-header3 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-company1 {
  color: rgb(255, 255, 255);
  font-size: 90px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
}
.home-description2 {
  color: rgb(255, 255, 255);
  font-size: 35px;
  max-width: 70%;
  text-align: center;
  font-family: "Verdana";
  line-height: 44px;
}
.home-statistics {
  width: 100%;
  display: flex;
  column-gap: 2rem;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.home-button2 {
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(225, 11, 227) 16.00%,rgb(77, 238, 234) 77.00%);
}
.home-text11 {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  font-weight: bolder;
}
.home-statistic-list {
  width: 80%;
  height: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: row;
  grid-template-columns: auto auto;
}
.home-clients {
  width: auto;
  padding-bottom: 5rem;
}
.home-header4 {
  width: 100%;
  display: flex;
  padding: 2rem 2rem;
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
}
.home-title3 {
  color: rgb(255, 255, 255);
  font-size: 6rem;
  align-self: center;
}
.home-grid1 {
  gap: 4rem;
  display: grid;
  padding: 4rem;
  grid-column-gap: 6rem;
  grid-template-columns: 1fr 1fr;
}
.home-column {
  display: grid;
  justify-items: center;
}
.home-column1 {
  display: grid;
  justify-content: center;
}
.home-client-list {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: none;
  max-width: 1280px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-bottom-width: 1px;
}
.home-title4 {
  color: rgb(255, 255, 255);
}
.home-row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-text12 {
  color: #999999;
  font-size: 24px;
  line-height: 36px;
}
.home-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-row1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-text13 {
  color: rgb(153, 153, 153);
  font-size: 24px;
  line-height: 36px;
}
.home-list2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-footer {
  width: 100vw;
  display: flex;
  justify-content: space-around;
}
.home-information {
  gap: var(--dl-space-space-twounits);
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: 60px;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-details {
  gap: var(--dl-space-space-twounits);
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: flex-start;
  margin-bottom: 13px;
  flex-direction: column;
  justify-content: center;
}
.home-header6 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-branding2 {
  gap: var(--dl-space-space-halfunit);
  width: 341px;
  align-items: center;
  flex-direction: column;
}
.home-image06 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  display: none;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-image07 {
  width: auto;
  margin-top: 0 auto;
  object-fit: contain;
}
.home-location {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-value {
  color: var(--dl-color-gray-white);
  font-size: 2.5rem;
  max-width: 560px;
  align-self: flex-start;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 48px;
}
.home-location1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  align-items: center;
  padding-left: 0px;
  flex-direction: column;
}
.home-caption {
  color: var(--dl-color-gray-white);
  font-size: 2.5rem;
  line-height: 36px;
  /* text-align:center; */
}
.home-value1 {
  color: var(--dl-color-gray-white);
  font-size: 2rem;
  max-width: 560px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
}
.home-link08 {
  margin: auto;
  background-image: linear-gradient(90deg, rgb(225, 11, 227) 16.00%,rgb(77, 238, 234) 77.00%);
}
.home-text14 {
  color: rgb(255, 255, 255);
}
.home-links2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-link09 {
  display: contents;
}
.home-image08 {
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.home-link10 {
  display: contents;
}
.home-image09 {
  width: var(--dl-size-size-small);
  height: auto;
  object-fit: cover;
  text-decoration: none;
}
.home-link11 {
  display: contents;
}
.home-image10 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  text-decoration: none;
}
.home-link12 {
  display: contents;
}
.home-image11 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  text-decoration: none;
}
.home-image12 {
  width: 40%;
  padding: 20px 20px;
  object-fit: fill;
  background-color: var(--dl-color-gray-black);
}
@media(max-width: 1600px) {
  .home-link04 {
    padding: 0.5rem 0.5rem;
    /* font-size: 1.5rem; */
  }
  .home-content {
    width: auto;
    max-width: auto;
  }
  .home-featured {
    width: auto;
  }
  .home-grid {
    grid-gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-statistics {
    column-gap: 3rem;
  }
  .home-button2 {
    padding: 1rem 1rem;
  }
  .home-text11 {
    font-size: 1.8rem;
  }
  .home-statistic-list {
    width: 75%;
  }
  .home-grid1 {
    gap: 1rem;
  }
  .home-information {
    width: 50%;
    padding-left: var(--dl-space-space-unit);
  }
  .home-details {
    align-self: center;
    align-items: flex-start;
  }
  .home-header6 {
    align-self: stretch;
    align-items: stretch;
  }
  .home-image07 {
    width: auto;
  }
  .home-links2 {
    width: 70%;
  }
  .home-image12 {
    width: 50%;
  }
}
@media(max-width: 1200px) {
  .home-branding {
    gap: var(--dl-space-space-twounits);
    width: 40%;
  }
  .home-items {
    gap: var(--dl-space-space-twounits);
    width: 60%;
    justify-content: center;
  }
  .home-links {
    gap: var(--dl-space-space-threeunits);
  }
  .home-link04 {
    padding: 0.5rem 0.5rem;
    font-size: 1.2rem;
    align-self: center;
    padding-left: 12px;
    padding-right: 12px;
  }
  .home-description1 {
    width: 100%;
    padding: 1rem 2rem;
    column-gap: var(--dl-space-space-twounits);
  }
  .home-content {
    width: auto;
    max-width: auto;
  }
  .home-paragraph {
    font-size: 2.3rem;
  }
  .home-featured {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-header1 {
    align-items: center;
    justify-content: space-between;
  }
  .home-title1 {
    color: var(--dl-color-gray-black);
    font-size: 4.5rem;
    align-self: center;
    text-align: center;
  }
  .home-list {
    width: 100%;
    flex-wrap: wrap;
  }
  .home-text09 {
    font-size: 2.2rem;
  }
  .home-services {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-title2 {
    font-size: 6rem;
  }
  .home-grid {
    align-self: center;
  }
  .home-header3 {
    margin-bottom: 30px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-description2 {
    font-size: 30px;
  }
  .home-statistics {
    column-gap: 2rem;
    justify-content: center;
  }
  .home-text11 {
    font-size: 1.8rem;
  }
  .home-statistic-list {
    width: 70%;
  }
  .home-header4 {
    padding: 1rem;
  }
  .home-grid1 {
    grid-template-columns: auto;
  }
  .home-information {
    width: 40%;
    widows: 40%;
    align-self: stretch;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .home-details {
    width: 90%;
  }
  .home-header6 {
    align-self: stretch;
    align-items: center;
  }
  .home-image07 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-value {
    font-size: 1.8rem;
  }
  .home-caption {
    font-size: 1.8rem;
  }
  .home-value1 {
    font-size: 1.5rem;
  }
  .home-link08 {
    padding: 0.5rem 0.5rem;
    font-size: 1.6rem;
  }
  .home-links2 {
    width: 90%;
  }
  .home-image12 {
    width: 60%;
    height: auto;
  }
}
@media(max-width: 991px) {
  .home-navbar-interactive {
    width: 100%;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .home-branding {
    gap: var(--dl-space-space-unit);
    width: 30%;
  }
  .home-image01 {
    width: 55%;
  }
  .home-items {
    gap: var(--dl-space-space-twounits);
    width: 70%;
    justify-content: flex-end;
  }
  .home-links {
    gap: var(--dl-space-space-unit);
  }
  .home-link04 {
    width: auto;
    font-size: 16px;
    text-align: center;
    border-radius: 40px;
  }
  .home-text {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .home-button {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-image02 {
    width: 18px;
    height: 18px;
    display: flex;
  }
  .home-text01 {
    display: none;
  }
  .home-button1 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    border-radius: 40px;
  }
  .home-text06 {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .home-header {
    height: 100%;
    justify-content: center;
  }
  .home-title {
    /* font-size: 6rem; */
    /* max-width: 40rem; */
    /* line-height: 110px; */
    /* width: 100%; */
    /* font-size: 7rem; */
    font-size: 5rem;
    max-width: 35rem;
    align-self: flex-start;
    font-weight: normal;
    /* line-height: 115px; */
    line-height:5rem;
    text-transform: uppercase;
  }
  .home-description {
    /* font-size: 1.8rem; */
    max-width: 45rem;
    /* width: 100%; */
    /* font-size: 2.1rem; */
    font-size: 1.5rem;
    /* max-width: 50rem; */
    font-family: "Verdana";
    /* line-height: 36px; */
    line-height:2rem;
  }
  .home-description1 {
    gap: var(--dl-space-space-threeunits);
    width: auto;
    height: 1000px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-content {
    max-width: 900px;
  }
  .home-text07 {
    gap: var(--dl-space-space-unit);
  }
  .home-paragraph {
    font-size: 2.1rem;
    align-self: center;
    text-align: center;
    line-height: 2.3rem;
  }
  .home-link05 {
    width: auto;
    font-size: 25px;
    text-align: center;
    border-radius: 40px;
  }
  .home-text08 {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .home-featured {
    height: fit-content;
  }
  .home-header1 {
    padding: 2rem 2rem;
  }
  .home-title1 {
    font-size: 3.5rem;
    line-height: 3rem;
  }
  .home-client {
    margin-top: 22px;
    padding-top: var(--dl-space-space-threeunits);
    border-color: transparent;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text09 {
    width: auto;
    font-size: 2rem;
    text-align: center;
    line-height: 2.5rem;
  }
  .home-text10 {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .home-services {
    gap: var(--dl-space-space-unit);
    align-items: center;
    padding-top: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-header2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-top-width: 1px;
  }
  .home-title2 {
    font-size: 5rem;
  }
  .home-grid {
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    grid-row-gap: var(--dl-space-space-threeunits);
    justify-items: center;
    grid-column-gap: var(--dl-space-space-threeunits);
  }
  .home-header3 {
    gap: var(--dl-space-space-threeunits);
    height: 15rem;
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-company1 {
    font-size: 50px;
    line-height: 27px;
  }
  .home-description2 {
    font-size: 20px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-statistics {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .home-button2 {
    padding: 0.8rem 0.8rem;
  }
  .home-text11 {
    font-size: 1.6rem;
    font-weight: bold;
  }
  .home-statistic-list {
    width: 100%;
    grid-gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-clients {
    padding-bottom: 1rem;
  }
  .home-header4 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-title3 {
    font-size: 50px;
    align-self: center;
  }
  .home-grid1 {
    padding: 2rem;
    grid-gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-column {
    gap: var(--dl-space-space-twounits);
  }
  .home-column1 {
    gap: var(--dl-space-space-twounits);
  }
  .home-client-list {
    gap: var(--dl-space-space-threeunits);
  }
  .home-header5 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-row {
    flex-direction: column;
  }
  .home-text12 {
    font-size: 10px;
    line-height: 15px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-list1 {
    width: 100%;
  }
  .home-row1 {
    flex-direction: column;
  }
  .home-text13 {
    font-size: 10px;
    line-height: 15px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-list2 {
    width: 100%;
  }
  .home-footer {
    flex-direction: row;
  }
  .home-information {
    width: 100%;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-details {
    gap: var(--dl-space-space-threeunits);
  }
  .home-value {
    font-size: 1.6rem;
    align-self: flex-start;
    text-align: left;
    line-height: 2rem;
  }
  .home-location1 {
    width: 100%;
    margin: auto;
    font-size: 20px;
    align-self: flex-start;
    align-items: center;
  }
  .home-caption {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .home-value1 {
    line-height: 2rem;
  }
  .home-text14 {
    font-style: normal;
    font-weight: 500;
  }
  .home-links2 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .home-image12 {
    width: 50%;
    height: auto;
    display: none;
  }
}
@media(max-width: 737px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image01 {
    width: 100%;
    display: block;
  }
  .home-items {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-button {
    color: #080808;
    padding: 18px;
    background-color: #c02aed;
  }
  .home-image02 {
    width: 14px;
    height: 14px;
    animation-name: none;
    animation-delay: 0s;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  .home-company {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
  }
  .home-button1 {
    background-image: linear-gradient(90deg, rgb(225, 11, 227) 16.00%,rgb(77, 238, 234) 77.00%);
  }
  .home-header {
    align-items: flex-start;
    padding-left: 0%;
    justify-content: center;
  }
  .home-title {
    font-size: 4rem;
    max-width: 30rem;
    line-height: 3.5rem;
  }
  .home-description {
    font-size: 1.2rem;
  }
  .home-description1 {
    gap: var(--dl-space-space-twounits);
    max-height: 100%;
  }
  .home-image03 {
    width: 50%;
    height: auto;
    object-fit: fill;
  }
  .home-paragraph {
    font-size: 1.6rem;
    align-self: center;
    text-align: left;
    line-height: 2.1rem;
  }
  .home-link05 {
    width: auto;
    font-size: 20px;
    padding-left: 10px;
  }
  .home-title1 {
    font-size: 3rem;
  }
  .home-list {
    width: 100%;
    flex-wrap: wrap;
  }
  .home-text09 {
    width: auto;
    text-align: center;
  }
  .home-image04 {
    width: 95%;
  }
  .home-header2 {
    align-items: center;
  }
  .home-grid {
    height: auto;
    align-self: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    grid-template-columns: repeat(1,1fr);
  }
  .home-header3 {
    height: auto;
    margin-bottom: 0px;
  }
  .home-description2 {
    max-width: 90%;
    line-height: 25px;
  }
  .home-text11 {
    font-size: 1.4rem;
  }
  .home-title3 {
    align-self: center;
  }
  .home-grid1 {
    display: flex;
    padding: 1rem;
    align-items: center;
  }
  .home-column {
    width: auto;
    align-items: center;
  }
  .home-column1 {
    width: auto;
    align-items: center;
  }
  .home-footer {
    width: 100%;
  }
  .home-information {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .home-header6 {
    width: 50%;
    align-self: center;
  }
  .home-branding2 {
    display: flex;
  }
  .home-image07 {
    width: 100%;
    align-self: flex-end;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
  }
  .home-value {
    font-size: 1.3rem;
    max-width: auto;
    align-self: flex-start;
    text-align: center;
  }
  .home-location1 {
    width: 50%;
    align-items: center;
  }
  .home-links2 {
    gap: 0px;
    margin: 15px 0;
    flex-direction: row;
    justify-content: space-around;
  }
  .home-image12 {
    display: none;
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image {
    padding: 10px 0;
    max-width: fit-content;
  }
  .home-image01 {
    display: none;
  }
  .home-mobile-menu {
    height: 52rem;
    padding: 16px;
  }
  .home-logo {
    border-radius: var(--dl-radius-radius-round);
  }
  .home-hero {
    height: 100%;
  }
  .home-video {
    height: 100%;
  }
  .home-header {
    min-width: 20rem;
  }
  .home-title {
    font-size: 2.9rem;
    max-width: 20rem;
    line-height: 70px;
  }
  .home-description {
    font-size: 1.2rem;
    max-width: 25rem;
  }
  .home-description1 {
    height: auto;
    padding: var(--dl-space-space-unit);
    column-gap: 10px;
  }
  .home-image03 {
    width: 50%;
    height: auto;
    align-self: center;
  }
  .home-paragraph {
    height: auto;
    font-size: 1rem;
    align-self: center;
    line-height: 1.2rem;
  }
  .home-link05 {
    width: auto;
    padding: 10px;
    font-size: 15px;
  }
  .home-featured {
    padding: 0px;
    align-items: stretch;
  }
  .home-header1 {
    padding: 0rem;
    flex-direction: column;
    justify-content: center;
  }
  .home-title1 {
    font-size: 2.3rem;
    line-height: 2.3rem;
  }
  .home-list {
    padding: 0px;
  }
  .home-text09 {
    font-size: 1.7rem;
  }
  .home-image04 {
    width: 100%;
  }
  .home-services {
    height: fit-content;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-header2 {
    align-items: center;
  }
  .home-title2 {
    font-size: 3.5rem;
  }
  .home-header3 {
    height: auto;
    margin-bottom: 20px;
  }
  .home-company1 {
    color: rgb(255, 255, 255);
    font-size: 45px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: 45px;
  }
  .home-description2 {
    text-align: center;
  }
  .home-statistics {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-unit);
  }
  .home-text11 {
    font-size: 1rem;
  }
  .home-statistic-list {
    margin-top: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-unit);
  }
  .home-header4 {
    padding: 0rem;
  }
  .home-title3 {
    font-size: 2.5rem;
    align-self: center;
    text-align: center;
    line-height: 2.5rem;
  }
  .home-grid1 {
    padding: 0rem;
  }
  .home-column {
    width: auto;
    align-self: center;
  }
  .home-column1 {
    width: auto;
    align-self: center;
  }
  .home-information {
    align-items: center;
  }
  .home-header6 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
  }
  .home-branding2 {
    width: fit-content;
    display: inline-block;
  }
  .home-image07 {
    margin: auto;
    display: inline-block;
  }
  .home-value {
    text-align: center;
  }
  .home-location1 {
    width: 100%;
    align-items: center;
  }
  .home-value1 {
    font-size: 1.05rem;
    word-break: break-all;
  }
  .home-link08 {
    margin: auto;
    display: block;
  }
  .home-text14 {
    font-size: 1.5rem;
  }
  .home-links2 {
    gap: 0px;
    max-width: 100%;
  }
  .home-image12 {
    width: var(--dl-size-size-small);
  }
}
