.author-author {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
.author-avatar {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.author-details {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.author-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.author-text1 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  line-height: 30px;
}
.author-root-class-name {
  display: none;
}
@media(max-width: 991px) {
  .author-author {
    gap: var(--dl-space-space-unit);
  }
  .author-avatar {
    width: 40px;
    height: 40px;
  }
  .author-text {
    font-size: 16px;
    line-height: 24px;
  }
  .author-text1 {
    font-size: 16px;
    line-height: 24px;
  }
}
